// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';
import Link from '../../components/link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _DSF8717: ImageType,
    _DSF8724: ImageType,
    _DSF8730: ImageType,
    _DSF8737: ImageType,
    _DSF8739_DSF8742: ImageType,
    _DSF8745: ImageType,
    _DSF8746: ImageType,
    _DSF8750: ImageType,
    _DSF8752: ImageType,
    _DSF8761: ImageType,
    _DSF8769: ImageType,
    _DSF8770: ImageType,
  },
};

class Blog_2018_11_11_Lomatium extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3AIII" title="Lomatium" />
        <p>
          For the final day of the trip we had gotten Fiery Furnace permits and
          intended to descend{' '}
          <Link to="http://ropewiki.com/index.php?title=Lomatium_%26_Krill_Loop&redirect=no">
            Lomatium and Krill canyons
          </Link>
          .
        </p>
        <Image
          image={data._DSF8717}
          caption="Ready to start exploring the Fiery Furnace"
        />
        <p>
          We went the scenic route - taking the Surprise Arch detour before
          heading to Lomatium.
        </p>
        <Image image={data._DSF8724} caption="Standing below Surprise Arch" />
        <p>
          After a few wrong turns - it had been a couple of years since I was
          here last - we eventually made our way to the up climb to get to the
          top of the canyon.
        </p>
        <Image
          image={data._DSF8730}
          caption="One of the many climbs to get up to the top of the fins"
        />
        <p>
          We made it to the top and were greeted with scenic views and terrible
          wind. Seriously, it was <em>so cold</em> up at the top.
        </p>
        <Image
          image={data._DSF8739_DSF8742}
          caption="Looking down into the Fiery Furnace from near the top of Lomatium"
        />
        <Image
          image={data._DSF8737}
          caption="The rest of the group freezing behind me"
        />
        <p>
          A little more winding and we made it to the top of the drainage. The
          first half of Lomatium consists of some really cool and difficult{' '}
          <GlossaryLink>down climbs</GlossaryLink>. From each drop it looks like
          it will be a <GlossaryLink>rappel.</GlossaryLink> Upon inspection,
          however, the way to down climb reveals itself.
        </p>
        <Image image={data._DSF8745} caption="Me at the top of the drainage" />
        <p>
          The first down climb is a steep ramp to a crack. The scariest part is
          the ramp and once into the crack each movement give the canyoneer more
          confidence. Some nail-biting moments and then we exit the crack and
          continue on.
        </p>
        <Image
          image={data._DSF8746}
          caption="Transition out of the crack on the first major down climb"
        />
        <p>
          Next comes the rabbit hole down climb - so named because sometimes
          there is a hole where canyoneers can climb through to get to the
          bottom of the climb. Today, however, it was not possible so we were
          forced to climb near a chockstone. I provided a top rope belay and
          each person successfully climbed down the obstacle.
        </p>
        <Image
          image={data._DSF8750}
          caption="Final major down climb by the rabbit hole"
        />
        <p>
          Next there was another small crack down climb with a dead tree at the
          bottom that helps people avoid some mud. After that there is a bit of
          hiking and we're at the top of the big rappel with a view of Abbey
          Arch.
        </p>
        <Image
          image={data._DSF8752}
          caption="Underneath Abbey Arch with the canyon dropping behind me"
        />
        <p>
          I had heard a couple of years ago some discussion on placing bolts at
          the top of the rappel. Having rappelled from the arch before it was
          illegal and from a tree before the bolts were placed, I agree that the
          bolts are the best option of the three and I'm glad they were placed.
        </p>
        <p>
          We rappelled down, pulled the ropes, and continued towards the final
          rappel. At the bottom were some hikers enjoying a bit of sun while
          having lunch. One of them asked if they could take our picture as we
          rappelled down. It was very cute and they seemed to enjoy themselves a
          lot. The hiker told us he had been in the Furnace many times and this
          was the first time he was able to catch people on the final rappel.
        </p>
        <Image image={data._DSF8761} caption="Last rappel of Lomatium" />
        <Image image={data._DSF8769} caption="Skull Arch" />
        <p>
          After the final rappel we decided to skip Krill and instead explore
          the south end of the Furnace for a while before heading out. I was
          actually really glad for that opportunity because there were a bunch
          of really cool places I had forgotten about.
        </p>
        <Image
          image={data._DSF8770}
          caption="Exploring up one of the side canyons"
        />
        <p>
          Great trip, great people. I'm glad I was able to visit such a
          beautiful and unique place with them and hope we can get through Krill
          soon.
        </p>
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-11-11-lomatium')(Blog_2018_11_11_Lomatium);

export const query = graphql`
  query {
    _DSF8717: file(
      relativePath: { eq: "images/blog/2018-11-11-lomatium/_DSF8717.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8724: file(
      relativePath: { eq: "images/blog/2018-11-11-lomatium/_DSF8724.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8730: file(
      relativePath: { eq: "images/blog/2018-11-11-lomatium/_DSF8730.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8737: file(
      relativePath: { eq: "images/blog/2018-11-11-lomatium/_DSF8737.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8739_DSF8742: file(
      relativePath: {
        eq: "images/blog/2018-11-11-lomatium/_DSF8739_DSF8742.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8745: file(
      relativePath: { eq: "images/blog/2018-11-11-lomatium/_DSF8745.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8746: file(
      relativePath: { eq: "images/blog/2018-11-11-lomatium/_DSF8746.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8750: file(
      relativePath: { eq: "images/blog/2018-11-11-lomatium/_DSF8750.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8752: file(
      relativePath: { eq: "images/blog/2018-11-11-lomatium/_DSF8752.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8761: file(
      relativePath: { eq: "images/blog/2018-11-11-lomatium/_DSF8761.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8769: file(
      relativePath: { eq: "images/blog/2018-11-11-lomatium/_DSF8769.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8770: file(
      relativePath: { eq: "images/blog/2018-11-11-lomatium/_DSF8770.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
